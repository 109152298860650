<template>
  <v-navigation-drawer
    id="navigation"
    v-resize="onResize"
    right
    app
    color="navbar"
    :temporary="isTemp"
    :value="isShown"
    @input="toggle"
  >
    <header class="nav-header">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Reclink" />
      </div>
      <p class="title">Reclink</p>
      <p class="name">{{ userName }}</p>
      <p class="email">Reclink ID: {{ nin }}</p>
    </header>
    <v-list>
      <v-list-item
        v-for="(link, i) in pages"
        :key="i"
        color="white"
        :to="link.to"
      >
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item color="white" :to="{ name: 'auth-logout' }">
        <v-list-item-icon>
          <v-icon>{{ logoutIcon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!--   <v-dialog
           v-model="showSettings"
           fullscreen
           transition="dialog-bottom-transition"
         >
            <template v-slot:activator="{ on, attrs }">
                 <v-list-item v-bind="attrs" v-on="on">
                   <v-list-item-icon>
                     <v-icon>{{ settingsIcon }}</v-icon>
                   </v-list-item-icon>
                   <v-list-item-content>
                     <v-list-item-title>Settings</v-list-item-title>
                   </v-list-item-content>
                </v-list-item>
        </template>

        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="closeSettings">
              <v-icon>{{ closeIcon }}</v-icon>
            </v-btn>
            <v-toolbar-title>Settings</v-toolbar-title>
          </v-toolbar>

          <v-card-text class="pa-5">
            <settings-form />
          </v-card-text>
        </v-card>
      </v-dialog>-->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mdiHome, mdiCog, mdiClose, mdiLogoutVariant } from "@mdi/js";

export default {
  name: "NavDrawer",
  data() {
    return {
      isMobile: null,
      showSettings: false,
      settingsIcon: mdiCog,
      closeIcon: mdiClose,
      logoutIcon: mdiLogoutVariant,
      pages: [
        {
          title: "Home",
          icon: mdiHome,
          to: { name: "app-landing" },
        },
      ],
    };
  },
  computed: {
    userName() {
      return this.$store.getters["auth/name"];
    },
    nin() {
      return this.$store.getters["auth/nin"];
    },
    isTemp() {
      return this.isMobile;
    },
    isShown() {
      if (!this.isMobile) {
        return true;
      } else {
        return this.$store.getters["components/isNavVisible"];
      }
    },
  },
  created() {
    this.isMobile = this.$vuetify.breakpoint.mobile;
  },
  methods: {
    toggle(evt) {
      if (evt === false) {
        this.$store.commit("components/toggleNav");
      }
    },

    closeSettings() {
      this.showSettings = false;
    },

    onResize() {
      const isMobile = this.$vuetify.breakpoint.mobile;
      if (this.isMobile !== isMobile) {
        this.isMobile = isMobile;
        this.$store.commit("components/toggleNav", false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/style/variables";
#navigation {
  padding: var(--set) 0 var(--seb) var(--sel) !important;
  header.nav-header {
    text-align: center;
    background-color: $color-primary;
    flex-grow: 0;
    padding: 20px 0 20px 0;
    border-bottom: 1px solid #fff;

    .logo {
      display: inline-block;
      margin: 0 auto;
      width: 110px;
      height: 110px;
    }

    .title {
      font-weight: bold;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 24px;
    }

    .name,
    .email {
      text-align: left;
      color: #fff;
      font-size: 16px;
      padding-left: 20px;
      margin: 0;
      font-weight: bold;
    }

    .email {
      font-weight: normal;
      margin-top: 0;
      font-style: italic;
    }
  }
}
</style>
