<template>
  <v-footer color="primary" class="footer">
    <v-btn color="white" icon class="btn" :to="{ name: 'app-programs' }" plain>
      <v-icon color="white">{{ calendarIcon }}</v-icon>
      <p class="">Programs</p>
    </v-btn>
    <v-dialog
      v-model="showSettings"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" color="white" icon class="btn" plain v-on="on">
          <v-icon>{{ dotsIcon }}</v-icon>
          <p class="">More</p>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeSettings">
            <v-icon>{{ closeIcon }}</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pa-5">
          <settings-form />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-footer>
</template>

<script>
import {
  mdiCalendarMonthOutline,
  mdiDotsHorizontal,
  mdiCog,
  mdiClose,
} from "@mdi/js";
import SettingsForm from "@/components/SettingsForm/index.vue";
export default {
  name: "Footer",
  components: {
    SettingsForm,
  },
  data() {
    return {
      showSettings: false,
      settingsIcon: mdiCog,
      closeIcon: mdiClose,
      calendarIcon: mdiCalendarMonthOutline,
      dotsIcon: mdiDotsHorizontal,
    };
  },
  methods: {
    closeSettings() {
      this.showSettings = false;
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .btn {
    margin-left: 10px;
    ::v-deep {
      .v-btn__content {
        flex-direction: column;
      }
    }
    p {
      font-size: 11px;
      font-weight: bold;
      margin: 0;
    }
  }
}
</style>
